import React from "react"
import { styled } from '@mui/material/styles';
import { graphql } from "gatsby"
import { Container, Grid, Typography } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import TitleHeader from '@components/TitleHeader'
import SEO from "../components/seo"
import ProductVariantCard from "@components/ProductVariantCard"
import currency from 'currency.js'
import { GRAIN_CONVEYORS, GRAIN_ELEVATORS } from '../const'
import DescriptionHeader from "../components/DescriptionHeader"

const PREFIX = 'NewConveyorsIndex';

const classes = {
  container: `${PREFIX}-container`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  list: `${PREFIX}-list`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },

  [`& .${classes.buttonContainer}`]: {
    textAlign: 'right',
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.list}`]: {
    listStyle: 'none',
    padding: 0,
  }
}));

const NewConveyorsIndex = ({
  data,
  pageContext: { pagePathStart }
}) => {
  const model = data.wpProduct
  const { products: groupedProducts } = model
  const { description: categoryDescription, name: categoryName } = data.wpProductCategory

  const cta = {
    name: 'View all price lists',
    url: `/all-price-lists#${model.id}`
  }

  if (!groupedProducts?.nodes?.length) {
    return (
      (<>
        <SEO title={model.name} />
        <Root maxWidth="md" disableGutters>
          <div className={classes.container}>
            <div className={classes.buttonContainer}>
              <Button to={cta.url} variant="contained" color="secondary">{cta.name}</Button>
            </div>
            <TitleHeader
              title={ pagePathStart === '/new-conveyors' ?
              GRAIN_CONVEYORS :
                pagePathStart === '/new-elevators' ?
                  GRAIN_ELEVATORS :
                    'Used equipment'
              }
              link={pagePathStart}
            />
            <p>
              No the product variations found.
            </p>
          </div>
        </Root>
      </>)
    );
  }

  return (
    <>
      <SEO title={model.name} />
      
      <Root maxWidth="md" disableGutters>
        <div className={classes.container}>
          <DescriptionHeader 
            description={categoryDescription} 
            subDescription={'Check out the modifications of the model range, having previously selected the model you are interested in.'}
            cta={cta}
          />
          <TitleHeader
            title={ pagePathStart === '/new-conveyors' ?
            GRAIN_CONVEYORS :
              pagePathStart === '/new-elevators' ?
                GRAIN_ELEVATORS :
                  'Used equipment'
            }
            titleLink={pagePathStart}
            subtitle={data.wpProduct.name}
            contentVerticalPosition="bottom"
          />
          <Grid component="ul" container className={classes.list} spacing={4}>
            {groupedProducts?.nodes
              .sort((productA, productB) => currency(productA.price).subtract(productB.price))
              .map(product => {
              return (
                <Grid key={product.id} item xs={12} sm={6} md={4}>
                  <ProductVariantCard parentProduct={data.wpProduct} product={product} pagePathStart={pagePathStart} />
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Root>
    </>
  )
}

export default NewConveyorsIndex

export const pageQuery = graphql`query NewProductVariants($productsGroupId: String!, $categoryName: String!) {
  wpProductCategory(name: {eq: $categoryName}) {
    name
    slug
    description
  }
  wpProduct(id: {eq: $productsGroupId}) {
    id
    name
    slug
    attributes {
      nodes {
        name
        options
      }
    }
    elevatorOptions {
      option1 {
        optionName
        optionPrice
      }
      option2 {
        optionName
        optionPrice
      }
      option3 {
        optionName
        optionPrice
      }
      option4 {
        optionName
        optionPrice
      }
      option5 {
        optionName
        optionPrice
      }
    }
    galleryImages {
      nodes {
        id
        altText
        localFile {
          publicURL
        }
      }
    }
    ... on WpGroupProduct {
      id
      name
      price
      products {
        nodes {
          id
          name
          productCategories {
            nodes {
              name
              slug
            }
          }
          slug
          attributes {
            nodes {
              name
              options
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 380, height: 294, quality: 80, layout: CONSTRAINED)
              }
              publicURL
            }
          }
          galleryImages {
            nodes {
              id
              altText
              localFile {
                publicURL
              }
            }
          }
          ... on WpSimpleProduct {
            price
          }
        }
      }
    }
  }
}
`
