import React, { useContext } from 'react';
import { IconButton } from '@mui/material'
import { FavoriteBorder, Favorite } from '@mui/icons-material'
import { MyPriceListSetContext, MyPriceListStateContext } from '../context/myPriceListContext'
import { formatOptions } from '@src/utility'

function FavoriteButton({ product, options, size, defaultColor, activeColor }) {
  const myPriceList = useContext(MyPriceListStateContext)
  const setMyPriceList = useContext(MyPriceListSetContext)

  const handleFavouriteClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let foundProduct = myPriceList.find(nextProduct => nextProduct.id === product.id)

    if(foundProduct) {
      setMyPriceList([
        ...myPriceList.filter(nextProduct => nextProduct.id !== product.id),
  
      ])
    } else {
      setMyPriceList([
        ...myPriceList,
        {
          id: product.id,
          price: product.price,
          name: product.name,
          category: product.productCategories?.nodes?.[0]?.name,
          quantity: 1,
          options: options ? formatOptions(options).map(option => ({ ...option, units: 0})) : null
        }
      ])
    }
  }
  
  return (
    <IconButton onClick={handleFavouriteClick} size={size || 'medium'}>
      {
        myPriceList.find(nextProduct => nextProduct.id === product.id) ?
        <Favorite style={{ color: activeColor || '#ffffff'}} /> :
        <FavoriteBorder style={{color: defaultColor || '#ffffff'}} />
      }
    </IconButton>
  );
}

export default FavoriteButton;