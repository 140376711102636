import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material'
import { Link } from "gatsby"
import parse from "html-react-parser"
import FsLightbox from 'fslightbox-react'
import { useStaticQuery, graphql } from 'gatsby'
import FavoriteButton from '@components/FavoriteButton'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PREFIX = 'ProductVariantCard';

const classes = {
  li: `${PREFIX}-li`,
  buttonBase: `${PREFIX}-buttonBase`,
  cardHeader: `${PREFIX}-cardHeader`,
  h2: `${PREFIX}-h2`,
  price: `${PREFIX}-price`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  iconButton: `${PREFIX}-iconButton`,
  icon: `${PREFIX}-icon`
};

const Root = styled('li')((
  {
    theme
  }
) => ({
  [`&.${classes.li}`]: {
    minHeight: 294,
    // '&:last-child': {
    //   marginBottom: 0
    // }
  },

  [`& .${classes.buttonBase}`]: {
    display: 'block', 
    width: '100%', 
    position: 'relative'
  },

  [`& .${classes.cardHeader}`]: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    textAlign: 'left',
    background: theme.palette.background.transparentGrey,
    padding: theme.spacing(2)
  },

  [`& .${classes.h2}`]: {
    margin: 0,
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    fontFamily: "Krub",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "117.4%",
    '& span': {
      display: 'block',
      lineHeight: '155%'
    }
  },

  [`& .${classes.price}`]: {
    color: theme.palette.common.white,
    fontFamily: "Prompt",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px"
  },

  [`& .${classes.actionsContainer}`]: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: 0,
    top: 0,
    background: "#E7B607A6", //65% opacity
  },

  [`& .${classes.iconButton}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.common.white
  }
}));

function ProductVariantCard({ parentProduct, product, pagePathStart }) {
  const placeholderImage = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "woocommerce-placeholder-1024.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 420, layout: CONSTRAINED)
    }
  }
}
`
  )


  const [toggler, setToggler] = useState(false);
  
  const handleZoomClick = e => {
    e.stopPropagation()
    e.preventDefault()
    setToggler(!toggler)
  }

  const uniqImageURLs = useMemo(() => {
    const productGalleryImageNodes = product.galleryImages?.nodes || []
    const parentProductGalleryImageNodes = parentProduct?.galleryImages?.nodes || []
    const imageURLs = [ product.image, ...productGalleryImageNodes, ...parentProductGalleryImageNodes ]
      .filter(image => image?.localFile?.publicURL)
      .map(image => image.localFile?.publicURL)
    return [ ...new Set(imageURLs)]
  }, [product.image, product.galleryImages, parentProduct.galleryImages])

  if(!product) return 'Product not found'

  return (
    <Root className={classes.li}>
      <article style={{ display: 'grid', height: 294}}>
      <GatsbyImage
        image={getImage(
          product.image?.localFile ||
          placeholderImage.file
        )}
        alt={product.name}
        style={{
          gridArea: "1/1",
        }}
        />
        <ButtonBase
        focusRipple
        component={Link}
        to={`${pagePathStart}/${parentProduct.slug}/${product.slug}`}
        style={{
          gridArea: "1/1",
          position: "relative",
        }}
        classes={{ root: classes.buttonBase}}
        >
          <header className={classes.cardHeader}>
            {
              product.price &&
              <strong className={classes.price}>{product.price}</strong>
            }
            <h2 className={classes.h2}>
              {
                product.attributes?.nodes?.map(attribute => (
                  <span key={attribute.name}>{`${attribute.options[0]} ${attribute.name}`}</span>
                ))
              }
              <span>{parse(product.name)}</span>
            </h2>
          </header>
          <div className={classes.actionsContainer}>
            {
              !!uniqImageURLs.length &&
              <IconButton onClick={handleZoomClick} className={classes.iconButton} size="large">
                <Search className={classes.icon} style={{fontSize: 30}} />
              </IconButton>
            }
            <FavoriteButton product={product} options={parentProduct?.elevatorOptions} size="medium" />
          </div>
        </ButtonBase>
      </article>

      <FsLightbox
        toggler={ toggler }
        sources={ uniqImageURLs }
      />
    </Root>
  );
}

export default ProductVariantCard;